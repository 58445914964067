import styled, { createGlobalStyle } from 'styled-components';

export const AppContainer = styled.div`
    width: 100%;
    padding: 10vh 0 20vh;
    text-align: center;
`;

export const GlobalStyles = createGlobalStyle`
    body {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif;
        background-color: #0d2b42;
        color: #fff;
    }
`;

export const Header = styled.header`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
`;

export const Logo = styled.img`
    max-width: 65vw;
    opacity: ${(props) => props.loaded ? 1 : 0};
    transition: opacity 0.84s ease-in;
`;

export const CompanyInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0;
        font-size: ${15 / 16}rem;
        line-height: 1.5rem;
    }
    a {
        display: flex;
        align-items: center;
        color: #fff;
        text-decoration: none;
        &:hover {
            color: #fff;
            text-decoration: none;
        }
        svg {
            display: inline-block;
            padding-right: 10px;
            color: #00a9e3;
        }
    }
`;

export const Cards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 90vw;
    max-width: 300px;
    margin: 10vh auto 0;
`;
