import styled from 'styled-components';

export const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: ${15 / 16}rem;
    text-align: left;
`;

export const Header = styled.p`
    font-weight: bold;
    span {
        display: block;
        font-weight: normal;
    }
;`

export const Contact = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    p {
        display: flex;
        align-items: center;
        margin: 0;
        line-height: 1.1rem;
        font-size: ${14 / 16}rem;
        a {
            color: #fff;
            text-decoration: none;
        }
        svg {
            padding-right: 5px;
            color: #00a9e3;
        }
    }
`;

export const Line = styled.div`
    position: relative;
    width: 100%;
    margin-top: 20px;
    border-bottom: 10px solid #00a9e3;
    div {
        position: absolute;
        top: 0;
        left: calc(50% - 25px);
        width: 50px;
        height: 10px;
        background-color: #0d2b42;
        transform: rotate(-45deg);
    }
`;
