import { FaEnvelopeSquare, FaPhone } from 'react-icons/fa';
import { CardContainer, Header, Contact, Line } from './styled';

function Card(props) {
    return (
        <CardContainer>
            <Header>
                {props.name}
                <span>{props.position}</span>
            </Header>
            <Contact>
                <p><a href={`tel:${props.phone}`}><FaPhone /> {props.phone}</a></p>
                <p><a href={`mailto:${props.email}`}><FaEnvelopeSquare /> {props.email}</a></p>
            </Contact>
            <Line>
                <div></div>
            </Line>
        </CardContainer>
    )
}

export { Card };
