import logo from './assets/logo.png';

import { FaEnvelopeSquare, FaGlobe } from 'react-icons/fa';
import { AppContainer, Header, CompanyInfo, Cards, Logo, GlobalStyles } from './styled';
import { useEffect, useState } from 'react';
import { Card } from './components/Card';

function App() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <AppContainer>
      <GlobalStyles />
      <Header>
        <Logo src={logo} alt="STARK Group Reality Logo" width="300" loaded={loaded} />
        <CompanyInfo>
          <p><b>STARK Group Reality, s.r.o.</b>Pri kríži 2194/12, 841 02 Bratislava</p>
          <p>
            <a href="mailto:office@starkgroupreality.sk">
              <FaEnvelopeSquare /> office@starkgroupreality.sk
            </a>
            <a href="https://www.starkgroupreality.sk">
              <FaGlobe /> www.starkgroupreality.sk
            </a>
          </p>
        </CompanyInfo>
      </Header>
      <Cards>
        {People.map((person) => {
          return (
            <Card key={person.name} {...person} />
          )
        })}
      </Cards>
    </AppContainer>
  );
}

const People = [
  {
    'name': 'František Bacigál',
    'position': 'realitný maklér',
    'phone': '+421 944 100 789',
    'email': 'f.bacigal@starkgroupreality.sk',
  },
  {
    'name': 'Scarlett Konečná',
    'position': 'realitný maklér',
    'phone': '+421 940 525 807',
    'email': 's.konecna@starkgroupreality.sk'
  },
  {
    'name': 'JUDr. Silvia Bacigál Štarková, LL.M.',
    'position': 'konateľ',
    'phone': '+421 905 111 585',
    'email': 's.bacigal@starkgroupreality.sk',
  }
];

export default App;
